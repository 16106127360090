import React, { useEffect, useRef, useState } from 'react'
import * as styles from './category.module.css'
import { Link } from 'gatsby'

const Category = ({ nodes, type = 'category', current }) => {
  const containerRef = useRef(null)
  const [visibleCategories, setVisibleCategories] = useState([])
  const [dropdownCategories, setDropdownCategories] = useState([])

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    const sortedNodes = nodes.sort((a, b) => {
      if (a.position !== null && b.position !== null) {
        return a.position - b.position
      }
      if (a.position !== null) {
        return -1
      }
      if (b.position !== null) {
        return 1
      }
      return 0
    })

    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth - 250
        let totalWidth = 0
        const newVisibleCategories = []
        const newDropdownCategories = []

        if (window.innerWidth <= 567) {
          sortedNodes.forEach(category => {
            newDropdownCategories.push(category)
          })
        } else {
          sortedNodes.forEach((category, index) => {
            const categoryWidth = 110
            if (totalWidth + categoryWidth <= containerWidth && newVisibleCategories.length < 5) {
              newVisibleCategories.push(category)
              totalWidth += categoryWidth
            } else {
              newDropdownCategories.push(category)
            }
          })
        }

        setVisibleCategories(newVisibleCategories)
        setDropdownCategories(newDropdownCategories)
      }
    }

    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver(handleResize)
    resizeObserver.observe(containerRef.current)

    handleResize()

    return () => {
      resizeObserver.disconnect()
    }
  }, [nodes])

  return (
    <div ref={containerRef} className={styles.categoriesContainer}>
      <Link to='/content-hub/' className={`${styles.categoriesItem} ${current === 'all' ? styles.current : ''}`}>All</Link>
      {visibleCategories.map((category, index) => (
        <Link to={`/content-hub/${type}/${category.slug?.current}/`} key={index} className={`${styles.categoriesItem} ${current === category.slug.current ? styles.current : ''}`}>{category.title}</Link>
      ))}
      {dropdownCategories.length > 0 && (
        <div className={`${styles.categoriesItem} ${styles.moreButton}`}>
          <div onClick={toggleMenu}><span>More </span>Categories</div>
          {isMenuOpen && (
            <div className={styles.dropdownContainer}>
              <div className={styles.dropdownHeader}>
                <span>All</span>
                <span className={styles.dropdownClose} onClick={toggleMenu} />
              </div>
              <div className={styles.dropdownContent}>
                {dropdownCategories.reduce((acc, category, index) => {
                  const groupIndex = Math.floor(index / 4)
                  if (!acc[groupIndex]) {
                    acc[groupIndex] = []
                  }
                  acc[groupIndex].push(
                    <Link to={`/content-hub/${type}/${category.slug?.current}/`} key={index} className={`${styles.categoriesItem} ${current === category.slug.current ? styles.current : ''}`}>{category.title}</Link>
                  )
                  return acc
                }, []).map((group, groupIndex) => (
                  <div key={groupIndex} className={styles.group}>
                    {group}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Category
